<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper justify-content-between">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <md-icon class="metismenu-icon">add_shopping_cart</md-icon>

          </div>
          <div class="text-black">Products Management
            <div class="page-title-subheading">Add, remove and edit products for user purchase
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4" style="display: inline-block">
          <md-card class="round-card">
              <md-card-content>
                <div class="col-md-6">
                  <div class="row align-items-center">
                  <div class="a">
                    <md-button to="/View_all_products" class="md-dense md-raised md-primary">View All Products</md-button>
                  </div>
                  </div>
                </div>
              </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <md-card md-with-hover class="round-card mb-5">
      <md-ripple>
        <md-card-content>
          <div class="container">
            <div class="col-md-12">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <md-field>
                      <label>Name</label>
                      <md-input v-model="form.name"></md-input>
                    </md-field>
                  </div>
                  <div class="col-md-3">
                    <md-field>
                      <label>Product Image</label>
                      <md-file @change="handleProductImage" accept="image/*"/>
                    </md-field>
                  </div>
                  <div class="col-md-3">
                    <md-field>
                      <label>Stock</label>
                      <md-input v-model="form.stock" type="number"></md-input>
                    </md-field>
                  </div>
                  <div class="col-md-3">
                    <md-field>
                      <label>Price</label>
                      <span class="md-prefix">$</span>
                      <md-input v-model="form.price" type="number"></md-input>
                    </md-field>
                  </div>
                  <div class="col-md-3">
                    <md-field>
                      <label>Product Options tag</label>
                      <md-input v-model="form.options_tag"></md-input>
                      <span class="md-helper-text">size, color, variety</span>
                    </md-field>
                  </div>
                  <div class="col-md-6">
                    <md-chips class="md-primary pulse-on-error" v-model="form.options"
                              md-placeholder="Configure Product Options..."
                              md-check-duplicated>
                      <div class="md-helper-text">Product Options</div>
                    </md-chips>
                  </div>
                  <div class="col-md-6">
                    <md-field>
                      <label>Product Options tag 2</label>
                      <md-input v-model="form.options_tag_1"></md-input>
                      <span class="md-helper-text">size, color, variety</span>
                    </md-field>
                  </div>
                  <div class="col-md-6">
                    <md-chips class="md-primary pulse-on-error" v-model="form.options_1"
                              md-placeholder="Configure Product Options 2..."
                              md-check-duplicated>
                      <div class="md-helper-text">Product Options</div>
                    </md-chips>
                  </div>
                  <div class="col-md-6">
                    <md-field>
                      <label>Product Description</label>
                      <md-textarea v-model="form.description"></md-textarea>
                      <md-icon>description</md-icon>
                    </md-field>
                  </div>
                  <div class="col-md-6">
                    <md-field>
                      <label>Product Description Images</label>
                      <md-file @change="handleDescImage" multiple accept="image/*"/>
                    </md-field>
                  </div>
                  <div class="d-flex submitBtn">
                    <md-button @click="addProduct()" class="md-dense md-raised md-primary">Submit</md-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </md-card-content>
      </md-ripple>
    </md-card>

  </div>
</template>

<script>
import {addProduct} from "@/services/market/marketService";

export default {
  name: "addProductsComponent",
  data: () => ({
    form: {
      name: "",
      image: "",
      stock: "",
      price: "",
      options_tag: "",
      options_tag_1: "",
      options: [],
      options_1: [],
      description: "",
      description_images: [],
    },
  }),
  methods: {
    handleProductImage(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.form.image = event.target.result;
      };
      reader.readAsDataURL(file);
    },

    handleDescImage(event) {
      let files = event.target.files;
      files.forEach((file)=>{
        let reader = new FileReader();
        reader.onload = (event) => {
          this.form.description_images.push(event.target.result);
        };
        reader.readAsDataURL(file);
      })
    },
    addProduct() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {

        addProduct(this.form)
            .then((response) => {
              loader.hide();
              this.$Progress.finish();

              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })
              Object.keys(this.form).forEach((i) => this.form[i] = null);

            })
            .catch((error) => {
              console.log(error.response);
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })

      }, 1000)
    }
  }
}
</script>

<style lang="css" scoped>
.pulse-on-error >>> .md-duplicated {
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out
}

@keyframes pulse {
  0% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
}

.submitBtn {
  margin: 10px auto;
}
</style>