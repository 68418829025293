<template>
  <div class="app-sidebar sidebar-shadow bg-slick-carbon sidebar-text-light">
    <div class="app-header__logo">
      <div class="logo-src"></div>
      <div class="header__pane ml-auto">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                  data-class="closed-sidebar">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
                    <span>
                        <button type="button"
                                class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span class="btn-icon-wrapper">
                                <i class="fa fa-ellipsis-v fa-w-6"></i>
                            </span>
                        </button>
                    </span>
    </div>
    <div class="scrollbar-sidebar">
      <div class="app-sidebar__inner">
        <ul class="vertical-nav-menu">

<!--          <li class="app-sidebar__heading">Menu</li>-->
<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/dashboard">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-home"></i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">dashboard</md-icon>-->
<!--                Dashboard-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->

          <li v-if="$STORE.state.user.type == 'admin'" class="app-sidebar__heading">Send Mail</li>
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/send_mail">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">email</md-icon>
                Send Mail
              </router-link>
            </div>
          </li>

<!--          <li class="app-sidebar__heading">Send SMS</li>-->
<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/generate_numbers">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">pin</md-icon>-->
<!--                Generate Numbers-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/send_sms">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">textsms</md-icon>-->
<!--                Send SMS-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->

<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/send_sms_smtp">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">textsms</md-icon>-->
<!--                SMTP to SMS-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->


<!--          <li v-if="$STORE.state.user.type == 'user'" class="app-sidebar__heading">Fund Wallet</li>-->
<!--          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'user'">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/fund">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">account_balance</md-icon>-->
<!--                Fund Wallet-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->


<!--          <li v-if="$STORE.state.user.type == 'admin'" class="app-sidebar__heading">PORT NUMBERS</li>-->
<!--          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/port_number">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">dialpad</md-icon>-->
<!--                Port Number-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->

<!--          <li class="app-sidebar__heading">TRANSACTIONS</li>-->
<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/credit_transactions">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">payments</md-icon>-->
<!--                Credit Transactions-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/debit_transactions">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">attach_money</md-icon>-->
<!--                Debit Transactions-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/smtp_to_sms_transactions">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">attach_money</md-icon>-->
<!--                SMTP to SMS Sub.-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->

          <li v-if="$STORE.state.user.type == 'admin'" class="app-sidebar__heading">User Management</li>
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/add_user">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">person_add</md-icon>
                Add User
              </router-link>
            </div>
          </li>
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/user_management">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">manage_accounts</md-icon>
                Manage User
              </router-link>
            </div>
          </li>

          <li class="app-sidebar__heading">Orders</li>
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/generate_numbers">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">pin</md-icon>
                Add CC / DC
              </router-link>
            </div>
          </li>
<!--          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'user'">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="#">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">textsms</md-icon>-->
<!--                Buy CC (Coming Soon)-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li @click="closeSideBar">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="/market_buy_links">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">textsms</md-icon>-->
<!--                {{ $STORE.state.user.type == 'admin' ? 'Links Order' : 'Buy Links' }}-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/my_orders">
                <!--              <router-link to="/mini_mart_orders">-->
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">shopping_cart</md-icon>
                Product Orders
              </router-link>
            </div>
          </li>
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/market_add_product">
                <!--              <router-link to="/mini_mart_orders">-->
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">add_shopping_cart</md-icon>
                Product Management
              </router-link>
            </div>
          </li>
<!--          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'user'">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="#">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">textsms</md-icon>-->
<!--                Buy SSN (Coming Soon)-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'user'">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="#">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">textsms</md-icon>-->
<!--                Logs(Coming Soon)-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->

<!--          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'user'">-->
<!--            <div class="glass_effect">-->
<!--              <router-link to="#">-->
<!--&lt;!&ndash;              <router-link to="/gift_card_trade">&ndash;&gt;-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">card_giftcard</md-icon>-->
<!--                Trade GiftCard (Coming Soon)-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'user'">-->
<!--            <div class="glass_effect">-->
<!--              &lt;!&ndash;              <router-link to="/">&ndash;&gt;-->
<!--              <router-link to="/mini_mart">-->
<!--                &lt;!&ndash;              <i class="metismenu-icon pe-7s-note2"> </i>&ndash;&gt;-->
<!--                <md-icon class="metismenu-icon">shopping_cart</md-icon>-->
<!--                Minimart-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'user'">
            <div class="glass_effect">
              <!--              <router-link to="/">-->
              <router-link to="/my_orders">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">local_shipping</md-icon>
                My Orders
              </router-link>
            </div>
          </li>
          <li v-if="$STORE.state.user.type == 'admin'" class="app-sidebar__heading">Settings</li>
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/add_country">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">pin</md-icon>
                Add Country (SMTP)
              </router-link>
            </div>
          </li>
          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/add_carrier">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">pin</md-icon>
                Add Carrier (SMTP)
              </router-link>
            </div>

          </li>

          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/add_link_name">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">pin</md-icon>
                Add Link (Bank or UI)
              </router-link>
            </div>
          </li>

          <li @click="closeSideBar" v-if="$STORE.state.user.type == 'admin'">
            <div class="glass_effect">
              <router-link to="/user_messaging">
                <!--              <i class="metismenu-icon pe-7s-note2"> </i>-->
                <md-icon class="metismenu-icon">pin</md-icon>
                Message Broadcast
              </router-link>
            </div>
          </li>


        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-sidebar",
  methods: {
    closeSideBar() {
      this.$emit('remove-sidebar-mobile-class');
    }
  }
}
</script>

<style scoped>
@import "../styles.scss";

.app-sidebar .scrollbar-sidebar {
  z-index: 15;
  width: 100%;
  overflow: auto !important;
}

.scrollbar-sidebar::-webkit-scrollbar-thumb {
  background-color: #515151;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}

.app-sidebar .scrollbar-sidebar::-webkit-scrollbar {
  width: 10px;
}
</style>