<script>
import {getImage} from "@/services/utils/imageViewerService";
import noDataComponent from "../../utils/noDataComponent.vue";
// import {getAllCart} from "../../../services/market/marketService";
import debit_credit from "@/assets/debit_credit_one.png";
import store from "@/store";
import {
  addOrderAddress,
  createOrder,
  getCartCount,
  getCrypto,
  getPayIDbyInvoice,
  SuccessOrder
} from "../../../services/market/marketService";


export default {
  name: "checkoutComponent",
  // eslint-disable-next-line vue/no-unused-components
  components: {noDataComponent},
  props: {
    show: Boolean,
    title: String,
    formData: null,
    getCheckoutData: null,
    CheckoutData: null,
  },
  data() {
    return {
      path: 'images/product_picture/',
      form: {
        quantity: 0,
        selected: null,
        isAll: false,
        full_name: '',
        mobile: '',
        street_add: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        isBilling: false,
        bill_name: '',
        bill_street_add: '',
        bill_city: '',
        bill_state: '',
        bill_zip: '',
        bill_country: '',
        paymentType: '',
      },
      active: 'first',
      first: false,
      isIframeEnabled: false,
      second: false,
      third: false,
      secondStepError: null,
      data: [],
      cryptoRespData: [],
      paymentTypeData: [
        {
          Image: '',
          text: 'Credit / Debit Card',
          value: 'card ',
          img: debit_credit
        },
        {
          Image: '',
          text: 'CryptoCurrency',
          value: 'crypto'
        },
      ],
      order_id: 0,
      intervalId: null,
      payloader: null,
    }
  },

  methods: {
    closeModal() {
      this.$emit('update:showCheckout', false);
    },

    getCardUrl() {

    },

    getCryptoUrl() {
      this.$Progress.start();
      this.payloader = this.$loading.show({});
      this.submitting = true;
      const newForm = {
        order_id: this.order_id,
        total: this.CheckoutData.total,
      }
      setTimeout(() => {
        getCrypto(newForm)
            .then((response) => {
              // loader.hide();
              this.isIframeEnabled = true;
              this.cryptoRespData = response.data.data;

              window.open(this.cryptoRespData.invoice_url, '_blank');

              this.intervalId = setInterval(() => {
                this.getPaymentByInvoice(this.cryptoRespData.id)
              }, 2000);

              this.$notify({
                message: "Please do not refresh or close this page this page till the Payment is confirmed",
                type: "warning",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 10500,
                offset: 50,
              })
            })
            .catch((error) => {
              this.payloader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 2500)
    },

    getPaymentByInvoice(id) {
      getPayIDbyInvoice(id).then((response) => {
        const list = response.data.data
        if (list.data.length > 0) {
          const getLastPayIDdata = list.data[list.data.length - 1];
          if (getLastPayIDdata.payment_status === 'finished') {

            clearInterval(this.intervalId);
            this.intervalId = null;
            let newSelection;
            if (this.form.isAll) {
              newSelection = 'all'
            } else {
              newSelection = this.form.selected
            }
            const newForm = {
              payment_id: getLastPayIDdata.payment_id,
              order_id: this.order_id,
              status: getLastPayIDdata.payment_status,
              selection: newSelection,
            }
            SuccessOrder(newForm).then(() => {
              this.payloader.hide();
              getCartCount()
                  .then((response) => {
                    store.dispatch({
                      type: 'updateCartCount',
                      data: response.data.data
                    });
                    // this.closeModal()
                    window.location.reload();
                  })
            })
          }
        }
      })
    },

    getImagePath(data) {
      return getImage(this.path, data)
    },

    getPayment() {
      // alert(this.form.paymentType)
      if (this.form.paymentType == 'crypto') {
        this.getCryptoUrl();
      } else {
        this.getCardUrl();
      }
    },

    goReview() {
      this.getCheckoutData()
    },

    setDone(id, index) {
      this[id] = true

      this.secondStepError = null

      if (index) {
        this.active = index
      }
    },

    setError() {
      this.secondStepError = 'This is an error!'
    },

    onClickPayNow() {
      let loader = this.$loading.show({});
      this.form.selected = this.formData.selected;
      this.form.isAll = this.formData.isAll;
      let newSelection;
      if (this.form.isAll) {
        newSelection = 'all'
      } else {
        newSelection = this.form.selected
      }

      const AddressNewForm = {
        full_name: this.form.full_name,
        mobile: this.form.mobile,
        street_add: this.form.street_add,
        city: this.form.city,
        state: this.form.state,
        zip: this.form.zip,
        country: this.form.country,
        isBilling: this.form.isBilling,
      };

      if (this.form.isBilling) {
        AddressNewForm['bill_name'] = this.form.bill_name,
            AddressNewForm['bill_street_add'] = this.form.bill_street_add,
            AddressNewForm['bill_city'] = this.form.bill_city,
            AddressNewForm['bill_state'] = this.form.bill_state,
            AddressNewForm['bill_zip'] = this.form.bill_zip,
            AddressNewForm['bill_country'] = this.form.bill_country
      }

      addOrderAddress(AddressNewForm).then((res) => {
        const OrdernewForm = {
          selection: newSelection,
          address_id: res.data.data,
          total: this.CheckoutData.total,
        }
        createOrder(OrdernewForm).then((response) => {
          loader.hide();
          this.order_id = response.data.data
          this.setDone('second', 'third')
        }).catch((error) => {
          loader.hide();
          this.$Progress.fail();
          if (error.response.status == 422) {
            for (let i in error.response.data.error) {
              setTimeout(() => {
                this.$notify({
                  message: error.response.data.error[i][0],
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }, 500);
            }
          }
        })
      }).catch((error) => {
        loader.hide();
        this.$Progress.fail();
        // console.log(error.response)
        if (error.response.status == 422) {
          for (let i in error.response.data.errors) {
            // console.log(error.response.data.errors[i][0])
            setTimeout(() => {
              this.$notify({
                message: error.response.data.errors[i][0],
                type: "error",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 4500,
                offset: 50,
              })
            }, 1000);
          }
        }
      })
      //
      //
      // const newForm = {
      //   selection: newSelection,
      //   address_id: 1,
      //   total: this.CheckoutData.total,
      // }
      // // console.log("hiiiii", this.form)
      // createOrder(newForm).then((response) => {
      //   this.order_id = response.data.data
      //   this.setDone('second', 'third')
      // })

    }


  },

  mounted() {
    this.form.selected = this.formData.selected;
    this.form.isAll = this.formData.isAll;
  },

  created() {
    this.form.selected = this.formData.selected;
    this.form.isAll = this.formData.isAll;
  }

}
</script>

<template>
  <div>
    <md-dialog :md-active.sync="show" class="round-card mobile_effect man-full">
      <div class="mt-3 row justify-content-between">
        <md-dialog-title class="text-capitalize">{{ title }}</md-dialog-title>
        <md-dialog-title class="text-capitalize ">
          <md-button @click="closeModal" class="md-icon-button md-dense md-raised md-primary">
            <md-icon>arrow_back</md-icon>
          </md-button>
        </md-dialog-title>
      </div>

      <md-dialog-content class="scrollable-container">
        <div>
          <md-steppers :md-active-step.sync="active" md-linear md-vertical>
            <md-step :md-error="secondStepError" id="first" md-label="Shipping" md-description="Shipping Details"
                     :md-done.sync="first">
              <div class="input_cont">
                <div class="input_cont_box">
                  <md-field>
                    <label>Recipient Full Name</label>
                    <md-input v-model="form.full_name"></md-input>
                    <!--                    <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>Phone Number</label>
                    <md-input v-model="form.mobile"></md-input>
                    <!--                    <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>Street Address</label>
                    <md-input v-model="form.street_add"></md-input>
                    <!--                    <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>City</label>
                    <md-input v-model="form.city"></md-input>
                    <!--                    <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>State/Province/Region</label>
                    <md-input v-model="form.state"></md-input>
                    <!--                    <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>Postal/Zip Code</label>
                    <md-input v-model="form.zip"></md-input>
                    <!--                    <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>Country</label>
                    <md-input v-model="form.country"></md-input>
                    <!--                    <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-checkbox v-model="form.isBilling">My Billing information is same as my shipping Information
                  </md-checkbox>
                </div>
              </div>
              <div class="text-center mt-5 mb-3" style="margin: 0 auto; font-size: 20px" v-if="!form.isBilling">
                Billing Information
              </div>
              <div v-if="!form.isBilling" class="input_cont">
                <div class="input_cont_box">
                  <md-field>
                    <label>Full Name</label>
                    <md-input v-model="form.bill_name"></md-input>
                    <!--                      <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>Street Address</label>
                    <md-input v-model="form.bill_street_add"></md-input>
                    <!--                      <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>City</label>
                    <md-input v-model="form.bill_city"></md-input>
                    <!--                      <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>State/Province/Region</label>
                    <md-input v-model="form.bill_state"></md-input>
                    <!--                      <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>Postal/Zip Code</label>
                    <md-input v-model="form.bill_zip"></md-input>
                    <!--                      <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
                <div class="input_cont_box">
                  <md-field>
                    <label>Country</label>
                    <md-input v-model="form.bill_country"></md-input>
                    <!--                      <span class="md-helper-text">Helper text</span>-->
                  </md-field>
                </div>
              </div>

              <div class="justify-content-end mt-5" style="display: flex !important;">
                <md-button class="md-raised md-primary" @click="() => {
                  this.goReview();
                  setDone('first', 'second')
                }">Proceed to Review
                </md-button>
              </div>

            </md-step>

            <md-step id="second" md-label="Product Review" md-description="Selected Product Review"
                     :md-error="secondStepError" :md-done.sync="second">

              <div class="box">
                <div class="flex-item-left" v-for="(product, index) in CheckoutData.data" :key="index">
                  <div class="cont">
                    <div class="prod_pic">
                      <img :src="getImagePath(product.product.image)" alt="">
                    </div>
                    <div class="prod_det">
                      <div class="top_lol">
                        <div class="prod_name mt-2">
                          {{ product.product.name }}
                        </div>
                      </div>
                      <div class="choice">
                        <div class="one">
                          {{ product.option_tag }}:{{ product.option }}/{{ product.option_tag1 }}:{{ product.option_1 }}
                        </div>
                        <!--                      <div class="two">-->

                        <!--                      </div>-->

                      </div>
                      <div class="price_cont">
                        <div class="price">${{ product.product.price }}</div>
                        <div class="amount">
                          Qty: {{ product.quantity }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="justify-content-end mt-5" style="display: flex !important;">
                <div style="justify-content: center;display: flex;align-items: center; font-size: 20px">
                  Total: ${{ CheckoutData.total }}
                </div>
                <md-button class="md-raised md-primary" :disabled="CheckoutData.total < 20" @click="onClickPayNow()">
                  PAY NOW
                  ({{ CheckoutData.count }})
                </md-button>
              </div>
              <div v-if="CheckoutData.total < 20" class="justify-content-end mt-1"
                   style="display: flex !important; color: red">
                To proceed with checkout, ensure your order total is above $20 USD.
              </div>
              <!--              <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
            </md-step>

            <md-step id="third" md-label="Payment" :md-error="secondStepError"
                     md-description="Pay with your desired payment platform"
                     :md-done.sync="third">
              <div>
                <div>
                  <div class="justify-content-center mt-3" style="display: flex !important;">
                    <h4>
                      Select your desired payment platform
                    </h4>
                  </div>

                  <div class="grid-wrapper grid-col-auto">
                    <!-- /.radio-card -->
                    <label v-for="(data, index) in paymentTypeData" :for="'radio-card-'+index" class="radio-card"
                           :key="index">
                      <input type="radio" v-model="form.paymentType" :value="data.value" name="radio-card"
                             :id="'radio-card-'+index"/>
                      <div class="card-content-wrapper">
                        <span class="check-icon"></span>
                        <div class="card-content">
                          <h4>{{ data.text }}</h4>
                        </div>
                      </div>
                    </label>
                    <!-- /.radio-card -->

                  </div>
                </div>
              </div>
              <md-button class="md-raised md-primary" @click="getPayment">Pay Now</md-button>
              <!--              <md-button class="md-raised md-primary" @click="setDone('third')">Done</md-button>-->
            </md-step>
          </md-steppers>
        </div>
      </md-dialog-content>

    </md-dialog>

  </div>
</template>

<style>
.round-card /deep/ div {
  border-radius: 20px;

  @media (max-width: 576px) {
    border-radius: 12px 12px 0 0;
  }
}

.man-full > div {
  //max-height: unset !important;
  //height: auto !important;
}


.mobile_effect {
  @media (max-width: 576px) {
    margin-top: 70px
  }
}


.man-full > div {
  width: 100% !important;
}


.input_cont {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.input_cont_box {
  flex: 1 1 calc(50% - 16px);
  box-sizing: border-box;
}

.box {
  //border: 1px solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Space boxes evenly */
}


.flex-item-left {
  width: calc(33% - 20px); /* 25% width for each box minus spacing */
  height: 100px; /* Set the height of each box */
  background-color: #666363;
  margin: 10px; /* Add spacing around each box */
  box-sizing: border-box;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .flex-item-left, label.radio-card {
    width: calc(100% - 20px) !important; /* Full width for each box on small screens */
  }
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
  .flex-item-left, label.radio-card {
    width: calc(50% - 20px) !important; /* Display 2 boxes per row */
  }
}

@media screen and (min-width: 1101px) and (max-width: 1399px) {
  .flex-item-left, label.radio-card {
    width: calc(33.3% - 20px) !important; /* Display 2 boxes per row */
  }
}

.cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.prod_pic {
  width: 100px;
  //border: 1px solid green;
  height: 100px;
}

.prod_det {
  width: calc(100% - 100px);
  //border: 1px solid red;
  height: 100px;
}

.prod_name {
  width: 100%;
  //border: 1px solid purple;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  padding-right: 5px;
}

.choice {
  margin-top: 10px;
  width: 100%;
  border-radius: 50px;
  background: #202020;
  color: #B6B6B6;
  //cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 25px;
}

.one {
  padding-left: 10px;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.price_cont {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.price {
  font-size: 16px;
  line-height: 25px;
  font-weight: bolder;

}

.amount {
  //border: 1px solid black;
  margin-right: 10px;
  font-size: 16px;
}

.top_lol {
  display: flex;
}


.grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Space boxes evenly */
}

/* ******************* Main Styeles : Radio Card */
label.radio-card {
  cursor: pointer;
  width: calc(33% - 20px); /* 25% width for each box minus spacing */
  height: 100px; /* Set the height of each box */
  margin: 10px; /* Add spacing around each box */
  box-sizing: border-box;
  border-radius: 10px;

}

label.radio-card .card-content-wrapper {
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  transition: 200ms linear;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #666363;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label.radio-card .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: relative;
}

label.radio-card .check-icon:before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}

label.radio-card input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  -moz-appearance: none;
}

.card-content {
  width: calc(100% - 20px);
  height: inherit;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

label.radio-card input[type='radio']:checked + .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #3057d5;
}

label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon {
  background: #3057d5;
  border-color: #3057d5;
  transform: scale(1.2);
}

label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon:before {
  transform: scale(1);
  opacity: 1;
}

label.radio-card input[type='radio']:focus + .card-content-wrapper .check-icon {
  box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
  border-color: #3056d5;
}

label.radio-card .card-content img {
  margin: 0 auto;
  width: auto;
  height: inherit;
  display: flex;

}

label.radio-card .card-content h4 {
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #1f2949;
  margin-bottom: 10px;
}

label.radio-card .card-content h5 {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #686d73;
}

.iframeCont {
  width: 100%;
  height: 100%;
}

.iframeCrypto {
  width: inherit;
  height: 100% !important;
  border: none;
}


</style>