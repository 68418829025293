<template>
  <div class="col-md-12">
    <div class="container">
      <div class="row">
        <div class="col-md-3 mb-3 pic_desc">
          <div class="product_img">
            <img :src="getImagePath(data.image)" alt="produc img" width="250px" id="productImg">
          </div>
        </div>

        <div class="col-md-5 mid_desc" id="style-1">
          <h5 class="text-capitalize"><b>{{ data.name }}</b></h5>
                    <p>Stock: {{ data.stock }}</p>
          <h5 class="details"><b>${{ data.price }}</b></h5>
          <hr>
          <div class="product-details">
            <div v-html="data.description"></div>
          </div>
          <div class="slider_box">
            <VueSlickCarousel v-bind="settings">
              <div class="image_box_desc" v-for="(image, index) in data.description_images.split(',')" :key="index">
                <div class="main">
                  <div>
                    <img :src="getDescImagePath(image)" alt="produc img">
                  </div>
                </div>
              </div>

            </VueSlickCarousel>
          </div>
        </div>

        <div class="col-md-4 add_desc">
          <form @submit.prevent="addCart()" class="container">

            <div>
              <!--            //Color-->
              <p><b class="text-capitalize">Select {{ data.options_tag }}</b></p>
              <div class="color-choose row">
                <div v-for="(options, index) in data.options.split(',')" :key="index">
                  <md-radio v-model="form.option" :value="options" :class='options'>{{ options }}</md-radio>
                </div>

              </div>
            </div>


            <div class="">
              <!--            //size-->
              <p><b class="text-capitalize"> Select {{ data.options_tag_1 }} </b></p>
              <div class="size-select row">
                <div class="" v-for="(options, index) in data.options_1.split(',')" :key="index">
                  <md-radio v-model="form.option_1" :value="options">{{ options }}</md-radio>
                </div>
              </div>
            </div>

            <div class="">
              <p><b>Quantity</b></p>
              <div class="col-md-12">
                <div class="row justify-content-between">
                  <md-button class="md-icon-button md-dense md-raised md-accent" @click="decrement"
                             :disabled="form.quantity === 0">
                    <md-icon>remove</md-icon>
                  </md-button>
                  <md-field class="col-md-4">
                    <label>Quatity</label>
                    <md-input v-model="form.quantity" @keyup="handleInput" min="0" :max="data.stock"></md-input>
                  </md-field>
                  <md-button class="md-icon-button md-dense md-raised bg-success" @click="increment"
                             :disabled="form.quantity >= data.stock">
                    <md-icon>add</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
            <div class="cart-btn">
              <button type="submit" :disabled="form.busy || form.option == ''  || form.option_1 == ''  || form.quantity == 0 ">
                <md-icon>shopping_cart</md-icon>
                Add to Cart
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getImage} from "@/services/utils/imageViewerService";
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {addCart, getCartCount} from "../../services/market/marketService";
import store from "@/store";


export default {
  name: "productDetailsModalComponent",
  props: {
    data: Object,
  },
  components: {VueSlickCarousel},

  data: () => ({
    path: 'images/product_picture/',
    desc_path: 'images/product_description_picture/',
    form: {
      option_tag: '',
      option: "",
      option_tag1: '',
      option_1: "",
      quantity: 0,
      product_id: '',
    },
    submitting: false,
    settings: {
      "dots": true,
      "arrows": false,
      "autoplay": true,
      "infinite": true,
      "slidesToShow": 1,
      "adaptiveHeight": true,
      "autoplaySpeed": 5000,
    }
  }),

  methods: {
    getImagePath(data) {
      return getImage(this.path, data)
    },
    getDescImagePath(data) {
      return getImage(this.desc_path, data)
    },
    increment() {
      this.form.quantity++;
    },
    decrement() {
      if (this.form.quantity > 0) {
        this.form.quantity--;
      }
    },
    handleInput(event) {
      // Remove non-numeric characters using a regular expression
      this.form.quantity = event.target.value.replace(/\D/g, '');

      if (/^0\d+/.test(event.target.value)) {
        // Remove the leading zero
        // alert(event.target.value.substring(1))
        this.form.quantity = event.target.value.substring(1)
      }
// alert(typeof  Number(this.data.stock))
      if (this.form.quantity > Number(this.data.stock)){
        this.form.quantity  = this.data.stock
      }

    },

    getCartCount(){
      getCartCount()
          .then((response) => {
            store.dispatch({
              type: 'updateCartCount',
              data: response.data.data
            })
          })
          .catch( error =>{
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    addCart() {
      this.form.option_tag = this.data.options_tag;
      this.form.option_tag1 = this.data.options_tag_1;
      this.form.product_id = this.data.id;

      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        addCart(this.form)
            .then((response) => {
              loader.hide();
              // this.convert(response.data.data);
              this.results = response.data.data;
              this.getCartCount();
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })
            })
            .catch((error) => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.errors) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.errors[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    }
  }
}
</script>

<style scoped>
.product_img {
  background-color: rgba(243, 244, 246, 0.527);
  border-radius: 10px;
  border: 2px solid rgb(231, 223, 223);
  position: relative;
  transform: scale(1);
  display: flex;
  justify-content: center;
}

.details {
  padding: 10px 0 5px 0;
}

.rate {
  float: left;
  height: 30px;
}

.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
  color: #ccc;
}

.rate:not(:checked) > label:before {
  content: '★ ';
}

.rate > input:checked ~ label {
  color: #ffc700;
}

.review a {
  margin-left: 80px;
  background-color: rgb(233, 150, 56);
  padding: 5px;
  color: black !important;
  border-radius: 5px;
}

.review {
  margin-bottom: 15px;
}

.product-details {
  margin-top: 15px;
  word-break: break-word;
}

.product-details p {
  margin-bottom: 10px !important;
}

.color-choose {
  margin-top: 15px;
}

.color-choose span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 15px;
}

.color-1 {
  background-color: white;
}

.color-2 {
  background-color: black;
}

.color-3 {
  background-color: red;
}

.color-4 {
  background-color: purple;
}

.color-choose input {
  display: none;
}

.color-choose input:checked + span {
  transform: scale(0.7);
  box-shadow: 0 0 0 4px #fff, 0 0 0 6px #000;
}

.col-md-5 form {
  font-size: 15px;
  font-weight: 600;
}

.radio-container {
  display: inline-block;
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #ccc;
  margin-right: 10px;
  margin-top: 10px;
}

.radio-container:hover, .radio-container:focus {
  background-color: black;
  color: rgb(233, 150, 56);
}

form .size-select {
  margin-bottom: 15px;
}

.size-select input:checked + span {
  color: rgb(233, 150, 56);
  font-weight: 800;
}

.size-select input {
  display: none;
}

.size-select span {
  padding: 5px;

}

.quantity {
  margin-top: 15px;
}

.quantity input {
  background-color: #eee;
  border: 0;
  outline: 0;
  padding: 2px 2px 2px 15px;
  width: 50px;
  border-radius: 12px;
}

.cart-btn {
  margin-top: 50px;
  text-align: center;
}

.cart-btn button {
  width: 80%;
  padding: 10px;
  font-size: 17px;
  border: 0;
  outline: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4) !important;
  cursor: pointer;
}

.cart-btn button:hover, button:focus {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  background-color: white !important;
  color: black !important;
}

.md-dialog-container {
  border-radius: 20px !important;
}

.image_box_desc {
  width: 100%;
  height: 200px;
}


.main {
  //border: 1px solid red;
  //background: pink;
  height: inherit;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}


.slider_box {
  margin-top: 20px;
  margin-bottom: 30px;
}

</style>