<template>
  <div ref="appContainer" class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">

    <!--      header/navbar begins-->
    <app-header ref="secondChild"></app-header>
    <!--      header/navbar ends-->

    <div class="app-main bg_1">

      <!--        sidebar begins-->
      <app-sidebar @remove-sidebar-mobile-class="removeSidebarMobileClass"></app-sidebar>
      <!--        sidebar ends-->

      <div class="app-main__outer">

        <!--          router view begins-->
        <router-view :key="$route.fullPath"/>
        <!--          router view ends-->

        <!--          footer begins-->
        <app-footer></app-footer>
        <!--          footer ends-->

      </div>
    </div>
  </div>
</template>

<script>
import appHeader from "./app-header";
import appSidebar from "./app-sidebar";
import appFooter from "./app-footer";
export default {
  name: "app-layout",
  components: {
    appFooter,
    appSidebar,
    appHeader
  },

  methods: {
    removeSidebarMobileClass() {
      const appContainer = this.$refs.appContainer;
      if (appContainer.classList.contains('sidebar-mobile-open')) {
        appContainer.classList.remove('sidebar-mobile-open');
      }
      this.$refs.secondChild.$emit('remove-sidebar-mobile-class');
    }
  }
}

</script>

<style>
.bg_1{
  background: url('../../../assets/background_image.png');
  background-size: cover;
}

</style>