<script>
import {getImage} from "@/services/utils/imageViewerService";
import noDataComponent from "../../utils/noDataComponent.vue";
import checkoutComponent from "./checkoutComponent.vue";
import {getAllCart, getSelectedProduct} from "../../../services/market/marketService";


export default {
  name: "cartComponent",
  components: {
    noDataComponent,
    checkoutComponent
  },
  props: {
    show: Boolean,
    title: String,
    data: {},
    raw: {},
    loadCart: null,
    removeProduct: null,
    updateQuantity: null,
  },
  data() {
    return {
      path: 'images/product_picture/',
      form: {
        quantity: 0,
        selected: null,
        isAll: false
      },
      showCheckoutDialog: false,
      selectAllProduct: false,
      selectedProducts: [],
      total: 0,
      checkoutDataRes: []
    }
  },

  methods: {
    closeModal() {
      this.$emit('update:showCart', false);
    },

    increment(index) {
      this.data[index].quantity++;
      const updated_amt = this.data[index].quantity;
      const id = this.data[index].id;
      this.updateQuantity(id, updated_amt, 'incremental')
    },

    decrement(index) {
      const curr = this.data[index].quantity;
      const id = this.data[index].id;
      if (Number(curr) === 1) {
        this.removeProduct(id);
        return
      }
      this.data[index].quantity--;
      const updated_amt = this.data[index].quantity;
      this.updateQuantity(id, updated_amt, 'decremental')
    },

    getImagePath(data) {
      return getImage(this.path, data)
    },

    selectAllProductInCart() {
      if (!this.selectAllProduct) return this.selectedProducts = []
      this.data.forEach(item => {
        if (!this.selectedProducts.includes(item.id)) {
          this.selectedProducts.push(item.id);
        }
      });
    },

    openCheckoutCart() {
      this.showCheckoutDialog = true
    },

    updateModalShow: function (value) {
      this.showCheckoutDialog = value;

      if (!value) {
        this.$emit('update:showCart', true);
      }
    },

    getTotal() {
      this.total = this.selectAllProduct ? this.raw.total : this.selectedProducts.length;
    },

    toCheckout() {
      if (this.selectedProducts.length < 1) {
        this.$notify({
          message: "no products selected, Don't do something fishy",
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      }

      this.form.selected = [...this.selectedProducts];
      this.form.isAll = this.selectAllProduct;

      let loader = this.$loading.show({});
      this.getCheckoutData();
      setTimeout(() => {
        loader.hide();
        this.openCheckoutCart()
        this.closeModal();
      }, 2500)

    },

     getCheckoutData()   {
      // let res;

      if (this.form.isAll || this.selectAllProduct) {
        getAllCart().then((response) => {
          this.checkoutDataRes =  response.data;
          // console.log(this.checkoutDataRes)
        })
      } else {
        const newForm = {
          selected: this.form.selected
        }
        getSelectedProduct(newForm).then((response) => {
          this.checkoutDataRes = response.data;
          // console.log(this.checkoutDataRes)
        })
      }

    },


  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    updatedData() {
      if (!this.selectAllProduct) return;

      let updatedData = JSON.parse(JSON.stringify((this.selectedProducts)));

      this.data.forEach(item => {
        if (!updatedData.includes(item.id)) {
          updatedData.push(item.id);
        }
      });
      // console.log('updatedData', updatedData)
      return updatedData;
    }
  },

  watch: {
    data: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        if (!this.selectAllProduct) return;

        newValue.forEach(item => {
          if (!this.selectedProducts.includes(item.id)) {
            this.selectedProducts.push(item.id);
          }
        });
      },
      deep: true
    }
  }
}
</script>

<template>
  <div>
    <md-dialog :md-active.sync="show" class="round-card mobile_effect man-full">
      <div class="mt-3 row justify-content-between">
        <md-dialog-title class="text-capitalize">{{ title }}</md-dialog-title>
        <md-dialog-title class="text-capitalize ">
          <md-button @click="closeModal" class="md-icon-button md-dense md-raised md-accent">
            <md-icon>close</md-icon>
          </md-button>
        </md-dialog-title>
      </div>

      <md-dialog-content class="scrollable-container">
        <div class="box">
          <div v-if="data.length < 1">
            <noDataComponent/>
          </div>
          <div class="flex-item-left" v-for="(product, index) in data" :key="index">
            <div class="cont">
              <div class="radio">
                <md-checkbox @change="() => { selectAllProduct = false }" class="md-primary" v-model="selectedProducts"
                             :value="product.id"></md-checkbox>
              </div>
              <div class="prod_pic">
                <img :src="getImagePath(product.product.image)" alt="">
              </div>
              <div class="prod_det">
                <div class="top_lol">
                  <div class="prod_name mt-2">
                    {{ product.product.name }}
                  </div>
                  <div class="deleteBtn">
                    <md-button @click="removeProduct(product.id)"
                               class="md-icon-button md-dense md-raised md-accent">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
                <div class="choice">
                  <div class="one">
                    {{ product.option_tag }}:{{ product.option }}/{{ product.option_tag1 }}:{{ product.option_1 }}
                  </div>
                  <div class="two">
                    >
                  </div>

                </div>
                <div class="price_cont">
                  <div class="price">${{ product.product.price }}</div>
                  <div class="amount">
                    <div class="wrapperv">
                      <button @click="decrement(index)"
                              :disabled="product.quantity === 0"
                              class="wrapbtn_minus">
                        <md-icon>remove</md-icon>
                      </button>
                      <input v-model.number="product.quantity"
                             class="quantity" disabled
                             type="text" name="name">
                      <button @click="increment(index)"
                              :disabled="product.quantity >= Number(product.product.stock)"
                              class="wrapbtn_add">
                        <md-icon>add</md-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3 mb-3">
            <div class="justify-content-center" style="display: flex !important;">
              <div>
                <pagination
                    :data="raw"
                    @pagination-change-page="loadCart"
                    :limit="5"
                    page="1"
                ></pagination>
              </div>

            </div>
          </div>
        </div>
      </md-dialog-content>

      <div class="bottom-card">
        <div class="bottom-container">

          <!--          Total: {{total}}-->
          <div class="d-flex" style="justify-content: space-between">
            <md-checkbox @change="selectAllProductInCart()" class="md-primary md-raised md-dense"
                         v-model="selectAllProduct">All
            </md-checkbox>
            <md-button :disabled="form.busy  || selectedProducts.length < 1" @click="toCheckout()"
                       class="md-primary md-raised md-dense">Checkout
            </md-button>
          </div>

        </div>
      </div>
    </md-dialog>

    <checkout-component
        :show="showCheckoutDialog"
        title="Checkout"
        @update:showCheckout="updateModalShow"
        :form-data="this.form"
        :get-checkout-data="getCheckoutData"
        :checkout-data="checkoutDataRes"
    ></checkout-component>

  </div>
</template>

<style>
.round-card /deep/ div {
  border-radius: 20px;

  @media (max-width: 576px) {
    border-radius: 12px 12px 0 0;
  }
}


.mobile_effect {
  @media (max-width: 576px) {
    margin-top: 70px
  }
}

.scrollable-container{
  margin-bottom: 25px !important;
}


.man-full > div {
  width: 100% !important;
}

.box {
  //border: 1px solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Space boxes evenly */
}


.flex-item-left {
  width: calc(25% - 20px); /* 25% width for each box minus spacing */
  height: 100px; /* Set the height of each box */
  background-color: #666363;
  margin: 10px; /* Add spacing around each box */
  box-sizing: border-box;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .flex-item-left {
    width: calc(100% - 20px); /* Full width for each box on small screens */
  }
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
  .flex-item-left {
    width: calc(50% - 20px); /* Display 2 boxes per row */
  }
}

@media screen and (min-width: 1101px) and (max-width: 1399px) {
  .flex-item-left {
    width: calc(33.3% - 20px); /* Display 2 boxes per row */
  }
}

.cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.prod_pic {
  width: 100px;
  //border: 1px solid green;
  height: 100px;
}

.prod_det {
  width: calc(100% - 100px);
  //border: 1px solid red;
  height: 100px;
}

.prod_name {
  width: 100%;
  //border: 1px solid purple;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  padding-right: 5px;
}

.choice {
  margin-top: 10px;
  width: 100%;
  border-radius: 50px;
  background: #202020;
  color: #B6B6B6;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.one {
  padding-left: 10px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two {
  padding-right: 10px;
}

.price_cont {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.price {
  font-size: 16px;
  line-height: 25px;
  font-weight: bolder;

}

.amount {
  //border: 1px solid black;
  margin-right: 5px;
}

.wrapperv {
  height: 25px;
  display: flex;
}

.quantity {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 25px;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
  //border-radius: 6px;
  //border: 1px solid #E1E8EE;
}

.wrapbtn_minus {
  width: 25px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  border: none;
}

.wrapbtn_add {
  width: 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
  border: none;
}

.quantity:focus {
  outline: 0;
}

.wrapbtn_minus,
.wrapbtn_add {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.top_lol {
  display: flex;
}

.deleteBtn {
  top: -10px;
  position: relative;
  width: 25px;
  height: 25px;
}

.radio {
  position: absolute;
  padding-top: 50px;
}

.bottom-card {
  width: 100%;
  height: 50px;
  background: white;
  position: fixed;
  /* top: 0; */
  bottom: 0;
  z-index: 10;
  margin-bottom: 25px !important;
}

.bottom-container {
  padding: 0 24px 24px;
}

</style>