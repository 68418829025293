<template>
  <div>
    <div class="mt-5">
      <div v-if="data.length < 1">
        <noDataComponent />
      </div>
    </div>

    <div class="row">
      <div v-if="data.length >= 1">
        <div   class="card mr-3 ml-3 mb-3" v-for="product in data" :key="product.id">

          <div class="imgBox">
            <img
                :src="getImagePath(product.image)"
                alt="mouse corsair" class="mouse">
          </div>

          <div class="contentBox">
            <h3>{{ product.name }}</h3>
            <h2 class="price">${{product.price}}  </h2>
            <a  class="buy">
              <ProductDetailsComponent
                  :title="product.name + ` Details`"
                  :data="product"
              />
            </a>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {getImage} from "@/services/utils/imageViewerService";
import ProductDetailsComponent from "@/components/utils/productDetailsComponent";
import noDataComponent from "./noDataComponent.vue";

export default {
  name: "productCardComponent",
  props: {
    data: []
  },
  components:{
    noDataComponent,
    ProductDetailsComponent
  },
  data: ()=> ({
    path: 'images/product_picture/'
  }),

  methods:{
    getImagePath(data) {
      return getImage(this.path, data)
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  max-width: 320px;
  height: 480px;
  background: #353535;
  border-radius: 20px;
  overflow: hidden;
}

.card::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background: #202020;
  transform: skewY(345deg);
  transition: 0.5s;
}

.card:hover::before {
  top: -70%;
  transform: skewY(390deg);
}

.card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  font-size: 6em;
  color: rgba(0, 0, 0, 0.1);
}

.card .imgBox {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  z-index: 1;
}

/*
.card .imgBox img {
    max-width: 100%;

    transition: .5s;
}

.card:hover .imgBox img {
    max-width: 50%;

}
*/
.card .contentBox {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.card .contentBox h3 {
  font-size: 18px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.card .contentBox .price {
  font-size: 24px;
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
}

.card .contentBox .buy {
  position: relative;
  top: 100px;
  opacity: 0;
  padding: 10px 30px;
  margin-top: 15px;
  color: #ffffff;
  text-decoration: none;
  background: #202020;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.5s;
  cursor: pointer;
}

.card:hover .contentBox .buy {
  top: 0;
  opacity: 1;
}

.mouse {
  height: 300px;
  width: auto;
}

</style>