<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper justify-content-between">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <md-icon class="metismenu-icon">shopping_cart</md-icon>
          </div>
          <div class="text-black">Minimart
            <div class="page-title-subheading">Delivery across the world.
            </div>
          </div>
        </div>

        <div class="col-md-8 mt-4" style="display: inline-block">
          <md-card class="round-card">
            <md-ripple>
              <md-card-content>
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-10">
                      <md-field md-clearable>
                        <md-icon>search</md-icon>
                        <label>Search</label>
                        <md-input @input="debounceSearch($event)"></md-input>
                      </md-field>
                    </div>
                    <div class="col-md-2">
                      <md-badge :md-content="$STORE.state.cartCount">
                        <md-button class="md-icon-button" @click="openCart">
                          <md-icon>shopping_cart</md-icon>
                        </md-button>
                      </md-badge>
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-ripple>
          </md-card>
        </div>

      </div>
    </div>


    <md-card class="round-card md-elevation-7 remove-bg mb-5">
      <md-card-content>
        <div class="container">
          <div class="col-md-12">
            <productCardComponent
                :data=products
            />
          </div>
          <div class="col-md-12 mb-3 mt-3">
            <div class="justify-content-center" style="display: flex !important;">
              <div>
                <pagination
                    :data="pagination"
                    @pagination-change-page="getProducts"
                    :limit="5"
                    page="1"
                ></pagination>
              </div>

            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <cart-component
        :show="showDialog"
        title="Cart"
        @update:showCart="updateModalShow"
        :data="cartProd"
        :raw="rawPagination"
        :load-cart="loadCart"
        :remove-product="confirmRemoveProduct"
        :update-quantity="UpdateQuantity"
    ></cart-component>

  </div>
</template>


<script>
import {getProducts, searchProducts} from "@/services/market/marketService";
import productCardComponent from "@/components/utils/productCardComponent";
import cartComponent from "./cartComponent.vue";
import {
  getCartCount,
  loadCart,
  removeProductCart,
  updateCartProdQuantity
} from "../../../services/market/marketService";
import store from "@/store";

export default {
  name: "miniMartComponent",
  components: {
    productCardComponent,
    cartComponent
  },
  data() {
    return {
      products: [],
      cartProd: [],
      filter: "All",
      pagination: {},
      rawPagination: {},
      submitting: false,
      useSearch: null,
      showDialog: false,
    }
  },
  methods: {
    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    prepPagination2(data) {
      this.rawPagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    getProducts(page = 1) {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        getProducts(page)
            .then((response) => {
              loader.hide();
              this.$Progress.finish();
              this.prepPagination(response.data);
              this.products = response.data.data;
            })
            .catch(error => {
              this.$notify({
                message: error.response.data.message,
                type: "error",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 4500,
                offset: 50,
              });
            })
      }, 1000)
    },

    SearchUsers(page, data) {
      searchProducts(page, data)
          .then((response) => {
            this.prepPagination(response.data);
            this.products = response.data.data;
          })
          .catch(error => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    debounceSearch(event) {
      clearTimeout(this.useSearch)
      this.useSearch = setTimeout(() => {
        if (event == '') {
          this.getProducts();
          this.searching = false;
        } else {
          this.SearchUsers(1, event);
        }
      }, 600)
    },

    getCartCount() {
      getCartCount()
          .then((response) => {
            store.dispatch({
              type: 'updateCartCount',
              data: response.data.data
            })
          })
          .catch(error => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    openCart() {
      this.loadCart()
      this.showDialog = true
    },

    updateModalShow(value) {
      this.showDialog = value;
    },

    loadCart(page = 1) {
      loadCart(page)
          .then((response) => {
            this.cartProd = response.data.data;
            this.prepPagination2(response.data);
          })
    },

    confirmRemoveProduct(id) {
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Remove Product From Cart ?',
        text: "Do you want to remove this Product from your Cart",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, Remove Product'
      }).then((result) => {
        if (result.isConfirmed) {
          this.doRemoveProduct(id)
        }
      })
    },

    doRemoveProduct(id) {
      removeProductCart(id)
          .then(() => {
            this.loadCart();
            this.getCartCount();
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    UpdateQuantity(id, amt, mode) {
      updateCartProdQuantity(id, amt, mode)
          .then(() => {
            this.loadCart()
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    }

  },

  mounted() {
    this.loadCart()
  },

  created() {
    this.getProducts();
    this.getCartCount();
    this.loadCart()
  }
}
</script>

<style scoped>
.remove-bg{
  background: unset !important;
}
</style>