/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import {GET_HEADERS, POST_HEADERS} from "@/config/axios_config";

let BASE_API_URL;
const form = new Form();


BASE_API_URL = process.env.VUE_APP_API_BASE_URL;


export async function linkPurchase(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/link/linkPurchase', POST_HEADERS)
}

export async function getLinkBanks() {
    return await axios.get(BASE_API_URL + '/market/get_link/banks', GET_HEADERS);
}

export async function loadTransactions(page) {
    return await axios.get(BASE_API_URL + '/market/link/linkPurchase/user' + "?page=" + page, GET_HEADERS);
}

export async function changeTransactionStatus(id, type) {
    return await axios.get(BASE_API_URL + `/market/link/linkPurchase/user/${type}/` + id, GET_HEADERS);
}

export async function uploadLinkPurchase(data, id) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/link/linkPurchase/user/upload/' + id, POST_HEADERS)
}


export async function addProduct(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/store', POST_HEADERS)
}

export async function loadProducts(page) {
    return await axios.get(BASE_API_URL + '/market/products/load' + "?page=" + page, GET_HEADERS);
}


export async function getProducts(page) {
    return await axios.get(BASE_API_URL + '/market/products' + "?page=" + page, GET_HEADERS);
}

export async function changeProductStatus(id, type) {
    return await axios.get(BASE_API_URL + `/market/product/${type}/` + id, GET_HEADERS);
}

export async function deleteProduct(id) {
    return await axios.delete(BASE_API_URL + `/market/product/` + id, GET_HEADERS);
}

export async function searchProducts(page, data) {
    return await axios.get(BASE_API_URL + '/market/products/search/' + data + "/?page=" + page, GET_HEADERS);
}


export async function addCart(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/cart/store', POST_HEADERS)
}


export async function getCartCount() {
    return await axios.get(BASE_API_URL + `/market/products/cart`, GET_HEADERS);
}

export async function loadCart(page) {
    return await axios.get(BASE_API_URL + `/market/products/cart/index` + "?page=" + page, GET_HEADERS);
}

export async function removeProductCart(id) {
    return await axios.delete(BASE_API_URL + `/market/product/cart/` + id, GET_HEADERS);
}

export async function updateCartProdQuantity(id, value, mode) {
    return await axios.get(BASE_API_URL + `/market/product/cart/` + id + '/' + value + '/' + mode, GET_HEADERS);
}

export async function getAllCart() {
    return await axios.get(BASE_API_URL + `/market/products/cart/index/getAll`, GET_HEADERS);
}

export async function getSelectedProduct(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/cart/index/getSelected', POST_HEADERS)
}

export async function getCrypto(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })

    return form.post(BASE_API_URL + '/market/products/cart/pay/v1', POST_HEADERS)
}

export async function createOrder(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/order/store', POST_HEADERS)
}


export async function getPayIDbyInvoice(id) {
    return await axios.get(BASE_API_URL + `/market/products/cart/pay/v1/getPay/` + id, GET_HEADERS);
}

export async function SuccessOrder(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/order/update', POST_HEADERS)
}

export async function loadOrder(page) {
    return await axios.get(BASE_API_URL + `/market/products/order/index` + "?page=" + page, GET_HEADERS);
}

export async function refreshPaymentStatus(id) {
    return await axios.get(BASE_API_URL + `/market/products/order/pay/refresh/` + id, GET_HEADERS);
}

export async function updateOrderStatus(id, status) {
    return await axios.get(BASE_API_URL + `/market/products/order/` + id + '/status/' + status, GET_HEADERS);
}

export async function uploadTracking(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/order/tracking', POST_HEADERS)
}

export async function orderProduct(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/order/product', POST_HEADERS)
}

export async function addOrderAddress(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/market/products/order/add/address', POST_HEADERS)
}


